<!--
组织管理
最后编辑人：张鹏
最后编辑时间：2023-6-16
最后编辑细节：
-->
<template>
  <el-container class="base-container">
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="4">
            <el-dropdown>
              <el-button type="success">
                <el-icon>
                  <Plus />
                </el-icon><span class="icon-right">新建</span> <el-icon>
                  <ArrowDown />
                </el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="addOrUpdateHandle('company')">新建网格</el-dropdown-item>
                  <el-dropdown-item @click="addOrUpdateHandle('department')">新建小区</el-dropdown-item>
                  <el-dropdown-item @click="addOrUpdateHandle('building')">新建楼栋</el-dropdown-item>
                  <el-dropdown-item @click="addOrUpdateHandle('unit')">新建单元</el-dropdown-item>
                  <!-- <el-dropdown-item @click="addOrUpdateHandle('floor')">新建楼层</el-dropdown-item> -->
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>
          <!-- <el-col :span="6" class="input-search">
            <el-input v-model="search.organ_name" placeholder="请输入网格名称" @keyup.enter="loadOrgan" @clear="loadOrgan" clearable size="default"></el-input>
          </el-col>
          <el-col :span="9">
            <el-button type="primary" size="default" @click="loadOrgan">
              <el-icon>
                <Search />
              </el-icon> <span class="icon-right">查询</span>
            </el-button>
            <el-button size="default" @click="toReset">
              <el-icon>
                <RefreshRight />
              </el-icon><span class="icon-right">重置</span>
            </el-button>
          </el-col> -->
          <el-col :span="20" style="text-align: right;">
            <el-tooltip v-if="openFlag" effect="dark" content="收起" placement="top">
              <el-link :underline="false" type="text" @click="toggleRowExpansion(openFlag)">
                <i class="iconfont icon-down"></i>
              </el-link>
            </el-tooltip>
            <el-tooltip v-else effect="dark" content="展开" placement="top">
              <el-link :underline="false" type="text" @click="toggleRowExpansion(openFlag)">
                <i class="iconfont icon-zhedie"></i>
              </el-link>
            </el-tooltip>
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="loadOrgan('refresh')">
                <el-icon :size="20">
                  <Refresh />
                </el-icon>
              </el-link>
            </el-tooltip>

          </el-col>
        </el-row>
        <div style="padding:5px;">
          <el-table row-key="id" ref="roleList" :height="tableHeight" :data="organListData" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" style="width: 100%"
                    @expand-change="expandChange" default-expand-all v-loading="loading">
            <template v-slot:empty>
              <el-empty description="暂无数据" :image-size="120"></el-empty>
            </template>
            <el-table-column prop="name" label="名称" show-overflow-tooltip>
              <template #default="scope">
                <i class="iconfont icon-zuzhiguanli" style="font-size: 18px;" v-if="scope.row.type ==='1'"></i>
                <i class="iconfont icon-tree-department-1" style="font-size: 18px;" v-if="scope.row.type ==='2'"></i>
                <i class="iconfont icon-tree-department-1" style="font-size: 18px;" v-if="scope.row.type ==='3'"></i>
                <i class="iconfont icon-tree-department-1" style="font-size: 18px;" v-if="scope.row.type ==='4'"></i>
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="level" label="层级" show-overflow-tooltip align="center" /> -->
            <!-- <el-table-column prop="typeName" label="类型" align="center" /> -->
            <el-table-column prop="CREATE_TIME" label="创建时间" align="center" />
            <!-- <el-table-column label="状态" fixed="right" align="center">
              <template #default="scope">
                <el-switch v-model="scope.row.STATE" active-value="1" inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" inline-prompt active-text="启" inactive-text="禁"
                           @change="switchState(scope.row)">
                </el-switch>
              </template>
            </el-table-column> -->
            <el-table-column fixed="right" label="操作" align="center" min-width="50px" max-width="60px">
              <template #default="scope">
                <el-button class="table-button" size="small" type="primary" @click="toEdit(scope.row)">编辑</el-button>
                <el-button class="table-button" size="small" type="danger" @click="toDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-dialog width="1000px" :title="OrganTitle" v-model="dailVisi" :close-on-click-modal="false" :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form :inline="true" :model="organData" :rules="rules" ref="organForm" label-width="130px">
              <el-form-item label="网格:" prop="organname" class="form-col">
                <el-input v-model="organData.organname" />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave('company')">保 存</el-button>
            </div>
          </div>
        </el-dialog>

        <el-dialog width="1000px" :title="depTitle" v-model="dailVisi1" :close-on-click-modal="false" :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form :inline="true" :model="depData" :rules="rules1" ref="depForm" label-width="130px">
              <el-form-item label="所属网格:" prop="organid" class="form-col">
                <el-cascader placeholder="请选择所属网格" style="width: 100%;" v-model="depData.organid" :props="props" clearable :options="organTree">
                </el-cascader>
              </el-form-item>
              <el-form-item label="小区名称:" prop="depname" class="form-col">
                <el-input v-model="depData.depname" />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave('department')">保 存</el-button>
            </div>
          </div>
        </el-dialog>
        <el-dialog width="1000px" :title="budingTitle" v-model="dailVisi2" :close-on-click-modal="false" :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form :inline="true" :model="budingData" :rules="rules1" ref="depForm" label-width="130px">
              <el-form-item label="所属小区:" prop="organid" class="form-col">
                <el-cascader placeholder="请选择所属小区" style="width: 100%;" v-model="budingData.organid" :props="props1" clearable :options="epartmenTree">
                </el-cascader>
              </el-form-item>
              <el-form-item label="楼栋名称:" prop="depname" class="form-col">
                <el-input v-model="budingData.depname" />
              </el-form-item>
              <el-form-item label="房屋类型:" prop="housetype" class="form-col">
                <el-input v-model="budingData.housetype" />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave('building')">保 存</el-button>
            </div>
          </div>
        </el-dialog>

        <el-dialog width="1000px" :title="unitTitle" v-model="dailVisi3" :close-on-click-modal="false" :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form :inline="true" :model="unitData" :rules="rules1" ref="depForm" label-width="130px">
              <el-form-item label="所属楼栋:" prop="organid" class="form-col">
                <el-cascader style="width: 100%;" v-model="unitData.organid" :props="treeProps" clearable @change="toRoleDep($event)" :options="treeData">
                </el-cascader>
              </el-form-item>
              
              <el-form-item label="单元名称:" prop="depname" class="form-col">
                <el-input v-model="unitData.depname" />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave('unit')">保 存</el-button>
            </div>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>


export default {
  name: "organMge",
  data () {
    return {
      search: {
        organ_name: "",
      },
      indexList: [],
      tableHeight: 0,
      organListData: [],
      loading: false,
      openFlag: true,
      OrganTitle: "网格新增",
      budingTitle:"楼栋新增",
      unitTitle:"单元新增",
      dailVisi: false,
      organData: {
        belongorganid: null,
        organid: "",
        organname: "",
      },
      organTree: [],
      epartmenTree:[],
      props: {
        label: 'name', value: 'ORGAN_ID', children: 'children',
        emitPath: false,
        checkStrictly: true,
      },
      props1: {
        label: 'name', value: 'DEPARTMENT_ID', children: 'children',
        emitPath: false,
        checkStrictly: true,
      },
      rules: {
        organname: [{ required: true, message: "请填写网格名称", trigger: "blur" }, { max: 255, message: "不能超过250个字", trigger: "blur" }],
      },
      depTitle: "小区新增",
      dailVisi1: false,
      dailVisi2: false,
      dailVisi3: false,
      depData: {
        depid: "",
        organid: "",
        depname: "",
        notes: "",
      },
      budingData:{
        depid: "",
        organid: "",
        housetype:"",
        depname: "",
        notes: "",
      },
      unitData:{
        depid: "",
        organid: "",
        depname: "",
        notes: "",
      },
      treeData:[],
      treeProps:{
        label: 'text', value: 'value', children: 'children',
        emitPath: false,
      },
      rules1: {
        depname: [{ required: true, message: "请填写小区名称", trigger: "blur" }, { max: 255, message: "不能超过250个字", trigger: "blur" }],
        organid: [{ required: true, message: "请选择网格名称", trigger: "blur" }],
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadOrgan()
      this.queryBuding()
      this.loadData()
    })
  },
  beforeUnmount () {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    // 树change
    toRoleDep (data) {
      if (data === null) {
        this.unitData.organid = ""
        this.unitData.depid = ""
        return
      }
      const parts = data.split('-');
      console.log(parts);
      this.unitData.organid = data
      this.unitData.depid = ""
    },
    // 查询树
    loadData () {
      this.$axios.get("/api/permission/getorgantree")
        .then((response) => {
          const data = response.data
          if (data.success) {
            this.treeData = data.result
            this.treeData.forEach(item => {
              if (item.type === "company" && item.children.length === 0) {
                item["disabled"] = true
              } else {
                item.children.forEach(i => {
                  if (i.type === "company" && i.children.length === 0) {
                    i["disabled"] = true
                  }
                })
              }
            })
          } else {
            // this.$alert(response.data.result, "获取组织列表出错")
          }
        })
    },
    //查询所有
    calcTableHeight () {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    /** 判断是否展开收缩 */
    expandChange (row, expandedRows) {
      if (expandedRows) {
        this.openFlag = true
      } else {
        this.openFlag = false
      }
    },
    /** 展开收缩 */
    toggleRowExpansion (isExpansion) {
      this.openFlag = !isExpansion;
      this.toggleRowExpansion_forAll(this.organListData, this.openFlag);
    },
    toggleRowExpansion_forAll (data, isExpansion) {
      data.forEach(item => {
        this.$refs.roleList.toggleRowExpansion(item, isExpansion);
        if (item.children !== undefined && item.children != null) {
          this.toggleRowExpansion_forAll(item.children, isExpansion);
        }
      })
    },
    // 查询网格
    loadOrgan (d) {
      this.loading = true
      var that = this
      this.$axios.post("/api/organ/loadorgan", this.search)
        .then((response) => {
          if (response.data.success) {
            that.loading = false
            this.organListData = response.data.result
            if (d === "refresh") {
              this.$message({ message: "刷新成功", type: "success" })
            }
          } else {
            this.$alert(response.data.result, "查询列表出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "查询列表出错")
        })

      this.$axios.post("/api/organ/loadorgantree", this.search)
        .then((response) => {
          if (response.data.success) {
            this.organTree = response.data.result
          } else {
            this.$alert(response.data.result, "查询列表出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "查询列表出错")
        })
    },
    // 查询小区
    queryBuding(){
      this.$axios.post("/api/organ/departmenttree", this.search)
        .then((response) => {
          if (response.data.success) {
            this.epartmenTree = response.data.result
          } else {
            this.$alert(response.data.result, "查询列表出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "查询列表出错")
        })
    },
    toReset () {
      this.search.organ_name = ""
      this.loadOrgan()
    },

    addOrUpdateHandle (type) {
      if (type === 'company') {
        this.addOrUpdateOrganize()
      }
      if (type === 'department') {
        this.addOrUpdateDep()
      }
      if (type === 'building') {
        this.addOrUpdatebuil()
      }
      if (type === 'unit') {
        this.addOrUpdateunit()
      }
      if (type === 'floor') {
        this.addOrUpdatefloor()
      }
    },
    // 网格
    addOrUpdateOrganize () {
      this.dailVisi = true
      this.organData.belongorganid = null
      this.organData.organid = ""
      this.organData.organname = ""
      if (this.$refs.organForm)
        this.$refs.organForm.clearValidate()
    },
    // 小区
    addOrUpdateDep () {
      this.dailVisi1 = true
      this.depData.depid = ""
      this.depData.organid = ""
      this.depData.depname = ""
      if (this.$refs.depForm)
        this.$refs.depForm.clearValidate()
    },
    // 楼栋
    addOrUpdatebuil () {
      this.queryBuding()
      this.dailVisi2 = true
      this.budingData.depid = ""
      this.budingData.organid = ""
      this.budingData.depname = ""
      this.budingData.housetype = ""
      if (this.$refs.depForm)
        this.$refs.depForm.clearValidate()
    },
    // 单元
    addOrUpdateunit () {
      this.dailVisi3 = true
      this.unitData.depid = ""
      this.unitData.organid = ""
      this.unitData.depname = ""
      if (this.$refs.depForm)
        this.$refs.depForm.clearValidate()
    },
    // 楼层
    addOrUpdatefloor () {

    },
    toEdit (data) {
      if (data.type === '1') {
        this.organData.belongorganid = data.BELONG_ORGAN_ID
        this.organData.organid = data.ORGAN_ID
        this.organData.organname = data.ORGAN_NAME
        this.OrganTitle = "网格编辑"
        this.dailVisi = true
        if (this.$refs.organForm)
          this.$refs.organForm.clearValidate()
      } 
      if(data.type === '2') {
        this.depData.depid = data.DEPARTMENT_ID
        this.depData.organid = data.ORGAN_ID
        this.depData.depname = data.DEPARTMENT_NAME
        this.depTitle = "小区编辑"
        this.dailVisi1 = true
        if (this.$refs.depForm)
          this.$refs.depForm.clearValidate()
      }
      if(data.type === '3') {
        this.budingData.depid = data.BUILDING_ID
        this.budingData.organid = data.DEPARTMENT_ID
        this.budingData.depname = data.name
        this.budingData.housetype = data.HOUSETYPE
        console.log(data);
        this.budingTitle = "楼栋编辑"
        this.dailVisi2 = true
        if (this.$refs.depForm)
          this.$refs.depForm.clearValidate()
      }
      if(data.type === '4') {
        console.log(data);
        this.unitData.depid = data.UNIT_ID
        this.unitData.organid = data.BUILDING_ID
        this.unitData.depname = data.name
        this.unitTitle = "单元编辑"
        this.dailVisi3 = true
        if (this.$refs.depForm)
          this.$refs.depForm.clearValidate()
      }
    },

    toSave (type) {
      this.queryBuding()
      this.loadData()
      if (type === 'company') {
        this.$refs["organForm"].validate((valid) => {
          if (valid) {
            this.$axios.post("/api/organ/saveorgandata", this.organData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({ message: "网格保存成功", type: "success" })
                  this.dailVisi = false
                  this.loadOrgan()
                } else {
                  this.$alert(response.data.result, "网格保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "网格保存出错")
              })
          }
          else
            return false
        })
      } 
      if(type === 'department') {
        this.$refs["depForm"].validate((valid) => {
          if (valid) {
            this.$axios.post("/api/organ/savedepdata", this.depData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({ message: "小区保存成功", type: "success" })
                  this.dailVisi1 = false
                  this.loadOrgan()
                } else {
                  this.$alert(response.data.result, "小区保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "小区保存出错")
              })
          }
          else
            return false
        })
      }
      // 保存楼栋
      if(type === 'building') {
        this.$refs["depForm"].validate((valid) => {
          if (valid) {
            this.$axios.post("/api/organ/saveBulidingdata", this.budingData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({ message: "楼栋保存成功", type: "success" })
                  this.dailVisi2 = false
                  this.loadOrgan()
                } else {
                  this.$alert(response.data.result, "楼栋保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "楼栋保存出错")
              })
          }
          else
            return false
        })
      }
      if (type === "unit") {
        this.$refs["depForm"].validate((valid) => {
          if (valid) {
            this.$axios.post("/api/organ/saveUnitData", this.unitData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({ message: "楼栋保存成功", type: "success" })
                  this.dailVisi3 = false
                  this.loadOrgan()
                } else {
                  this.$alert(response.data.result, "楼栋保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "楼栋保存出错")
              })
          }
          else
            return false
        })
      }
    },
    //删除
    toDel (data) {
      if (data.type === '1') {
        this.$confirm("确认删除此网格下属全部小区吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios.post("/api/organ/delorgan", { organid: data.ORGAN_ID })
            .then((response) => {
              if (response.data.success) {
                this.$message({ message: "网格删除成功", type: "success" })
                this.loadOrgan()
              } else {
                this.$alert(response.data.result, "网格删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "网格删除出错")
            })
        })
      } 
      if (data.type === '2') {
        this.$confirm("确认删除此小区吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios.post("/api/organ/deldepartment", { organid: data.ORGAN_ID, depid: data.DEPARTMENT_ID })
            .then((response) => {
              if (response.data.success) {
                this.$message({ message: "小区删除成功", type: "success" })
                this.loadOrgan()
              } else {
                this.$alert(response.data.result, "小区删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "小区删除出错")
            })
        })
      }
      if (data.type === '3') {
        this.$confirm("确认删除此楼栋吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios.post("/api/organ/delBuliding", { organid: data.DEPARTMENT_ID, depid: data.BUILDING_ID })
            .then((response) => {
              if (response.data.success) {
                this.$message({ message: "楼栋删除成功", type: "success" })
                this.loadOrgan()
              } else {
                this.$alert(response.data.result, "楼栋删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "楼栋删除出错")
            })
        })
      }
      if (data.type === '4') {
        console.log(data);
        this.$confirm("确认删除此单元吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios.post("/api/organ/delUnit", { organid: data.BUILDING_ID, depid: data.UNIT_ID })
            .then((response) => {
              if (response.data.success) {
                this.$message({ message: "单元删除成功", type: "success" })
                this.loadOrgan()
              } else {
                this.$alert(response.data.result, "单元删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "单元删除出错")
            })
        })
      }
    },

    //切换状态
    switchState (data) {
      if (data.level === '1') {
        this.$axios.post("/api/organ/switchorgan", { organid: data.ORGAN_ID, state: data.STATE })
          .then((response) => {
            if (!response.data.success) {
              this.$alert(response.data.result, "网格状态切换出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "网格状态切换出错")
          })
        setTimeout(this.loadOrgan, 300)
      } else {
        this.$axios.post("/api/organ/switchdep", { depid: data.DEPARTMENT_ID, state: data.STATE })
          .then((response) => {
            if (!response.data.success) {
              this.$alert(response.data.result, "小区状态切换出错")
              this.loadOrgan()
            }
          })
          .catch((error) => {
            this.loadOrgan()
            this.$alert(error, "小区状态切换出错")
          })
      }

    },
  }
}
</script>

<style scoped>
:deep(.el-upload--picture-card) {
  width: 100px;
  height: 100px;
}
:deep(.el-upload) {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
:deep(.el-upload-list--picture-card .el-upload-list__item) {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
:deep(.el-upload-list--picture-card .el-upload-list__item-thumbnail) {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
:deep(.avatar) {
  width: 100px;
  height: 100px;
}

.margin-top {
  margin-top: 10px;
}
</style>
