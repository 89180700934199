/**
 页面路由定义
 最后编辑人：张鹏
 最后编辑时间：2024-1-16
 最后编辑细节：
 */
import {createRouter, createWebHistory} from 'vue-router'
import App from "@/App";
import MainPage from "@/components/MainPage";
import HomePage from "@/components/HomePage";
import PermissionMge from "@/components/organ/PermissionMge";
import RoleMge from "@/components/organ/RoleMge";
import OrganMge from "@/components/organ/OrganMge";
import UserMge from "@/components/organ/UserMge";
import personnelInfo from "@/components/organ/personnelInfo";
import UserRolePermission from "@/components/organ/UserRolePermission";

import SysLog from "@/components/sys/SysLog";
import LoginLog from "@/components/sys/LoginLog";
import CodeMge from "@/components/info/CodeMge";
import SubjectMge from "@/components/info/SubjectMge";
import InitialDataMge from "@/components/info/InitialDataMge";
import VoucherEnter from "@/components/voucher/VoucherEnter";
import WagesMge from "@/components/wages/WagesMge";
import LiabilitiesMge from "@/components/liabilities/LiabilitiesMge";
import BusinessMge from "@/components/info/BusinessMge";
import VoucherDetail from "@/components/voucher/VoucherDetail";
import MonthFinancialReport from "@/components/report/MonthFinancialReport";
import ProfitAndLossReport from "@/components/report/ProfitAndLossReport";
import DayRecordReport from "@/components/report/DayRecordReport";
import CashFlowReport from "@/components/report/CashFlowReport";
import AssetDepreciationMge from "@/components/asset/AssetDepreciationMge";
import assetInventoryBase from "@/components/asset/AssetInventoryBase";
import assetStorageQuery from "@/components/asset/AssetStorageQuery";
import assetStorageMge from "@/components/asset/AssetStorageMge";
import systemConfiguration from "@/components/sys/systemConfiguration";
import entryDetail from "@/components/entry/EntryDetail";
import subjectBalanceReport from "@/components/entry/SubjectBalanceReport";
import BalanceReport from "@/components/entryreport/BalanceReport";
import ProfitReport from "@/components/entryreport/ProfitReport";
import EntryCashFlowReport from "@/components/entryreport/EntryCashFlowReport";


const routes = [
    {
        path: '/',
        component: App,
        redirect: '/organ/organmge'
    },
    {
        path: '/mainpage',
        name: 'main-page',
        component: MainPage,
        children: [
            {
                path: '/homepage',
                name: 'home-page',
                component: HomePage
            },
            {
                path: '/organ/organmge',
                name: 'organ-mge',
                component: OrganMge
            },
            {
                path: '/organ/rolemge',
                name: 'role-mge',
                component: RoleMge
            },
            {
                path: '/organ/usermge',
                name: 'user-mge',
                component: UserMge
            },
            {
                path: '/organ/personnelInfo',
                name: 'personne-lInfo',
                component: personnelInfo
            },
            {
                path: '/organ/permissionmge',
                name: 'permission-mge',
                component: PermissionMge
            },
            {
                path: '/organ/userrolepermission',
                name: 'user-role-permission',
                component: UserRolePermission
            },
            {
                path: '/info/subjectmge',
                name: 'subject-mge',
                component: SubjectMge
            },
            {
                path: '/info/businessmge',
                name: 'business-mge',
                component: BusinessMge
            },
            {
                path: '/info/codemge',
                name: 'code-mge',
                component: CodeMge
            },
            {
                path: '/voucher/initialdatamge',
                name: 'initial-data-mge',
                component: InitialDataMge
            },
            {
                path: '/sys/systemConfiguration',
                name: 'system-configuration',
                component: systemConfiguration
            },
            {
                path: '/sys/syslog',
                name: 'syslog',
                component: SysLog
            },
            {
                path: '/sys/loginlog',
                name: 'login-log',
                component: LoginLog
            },
            {
                path: '/voucher/voucherenter',
                name: 'voucher-enter',
                component: VoucherEnter
            },
            {
                path: '/voucher/voucherdetail',
                name: 'voucher-detail',
                component: VoucherDetail
            },
            {
                path: '/wages/wagesMge',
                name: 'wages-Mge',
                component: WagesMge
            },
            {
                path: '/liabilities/liabilitiesMge',
                name: 'liabilities-Mge',
                component: LiabilitiesMge
            },
            {
                path: '/report/dayRecordReport',
                name: 'day-Record-Report',
                component: DayRecordReport
            },
            {
                path: '/report/monthFinancialReport',
                name: 'month-Financial-Report',
                component: MonthFinancialReport
            },
            {
                path: '/report/profitAndLossReport',
                name: 'profit-And-Loss-Report',
                component: ProfitAndLossReport
            },
            {
                path: '/report/cashflowreport',
                name: 'cash-flow-report',
                component: CashFlowReport
            },
            {
                path: '/asset/assetDepreciationMge',
                name: 'asset-Depreciation-Mge',
                component: AssetDepreciationMge
            },
            {
                path: '/asset/assetInventoryBase',
                name: 'asset-Inventory-Base',
                component: assetInventoryBase
            },
            {
                path: '/asset/assetStorageMge',
                name: 'asset-Storage-Mge',
                component: assetStorageMge
            },
            {
                path: '/asset/assetStorageQuery',
                name: 'asset-Storage-Query',
                component: assetStorageQuery
            },

            {
                path: '/entry/entryDetail',
                name: 'entry-Detail',
                component: entryDetail
            },
            {
                path: '/entry/subjectBalanceReport',
                name: 'subject-Balance-Report',
                component: subjectBalanceReport
            },
            {
                path: '/entryreport/balanceReport',
                name: 'balance-Report',
                component: BalanceReport
            },
            {
                path: '/entryreport/profitReport',
                name: 'profit-Report',
                component: ProfitReport
            },
            {
                path: '/entryreport/entryCashFlowReport',
                name: 'entry-Cash-Flow-Report',
                component: EntryCashFlowReport
            },
        ]
    },
]
const router = createRouter({
    history:createWebHistory(),
    routes
})

export default router;


